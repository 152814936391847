import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa'; // Importing an icon from react-icons

interface ListTileProps {
    title: string;
    subtitle?: string;      // Nullable subtitle prop
    route?: string;         // Nullable route prop
    isMoreButton?: boolean; // Nullable prop to show/hide the icon
}

const ListTile: React.FC<ListTileProps> = ({ title, subtitle, route, isMoreButton }) => {
    const navigate = useNavigate();

    const handleMoreClick = () => {
        if (route) {
            navigate(route); // Navigate to the specified route if it exists
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center border-bottom py-4">
            <div>
                <h5 className="mb-1">{title}</h5>
                {/* Render subtitle only if it is provided */}
                {subtitle && <p className="mb-0 text-muted">{subtitle}</p>}
            </div>
            {isMoreButton && route && ( // Render the icon only if isMoreButton is true and route is provided
                <div>
                    <FaChevronRight 
                        className="text-secondary"
                        style={{ cursor: 'pointer' }}
                        onClick={handleMoreClick} // Navigate to the specified route
                    />
                </div>
            )}
        </div>
    );
};

export default ListTile;
