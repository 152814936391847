import React, { useEffect, useRef, useState  } from 'react';
//import { useParams } from 'react-router-dom';
import { Layout } from '../../../components'; 
import Konva from 'konva';
import { FaFacebook, FaInstagram, FaRegStar, FaStar, FaStarHalfAlt, FaTwitter, FaYoutube } from 'react-icons/fa';

const ProductDetails: React.FC = () => {
    //const { id } = useParams<{ id: string }>(); // Extracting the product ID from the URL
    const konvaContainerRef = useRef<HTMLDivElement>(null);
    const [backgroundImageSrc, setBackgroundImageSrc] = useState('');
    const [stage, setStage] = useState<Konva.Stage | null>(null);
    const [transformer, setTransformer] = useState<Konva.Transformer | null>(null);
    const [size, setSize] = useState('Small');
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        const container = document.getElementById('container');
        const konvaContainer = konvaContainerRef.current;

        if (!container || !konvaContainer) return;

        const padding = 20;
        const width = konvaContainer.offsetWidth;
        const height = konvaContainer.offsetHeight;

        const newStage = new Konva.Stage({
            container: 'container',
            width: width,
            height: height,
        });

        setStage(newStage);

        const layer = new Konva.Layer();
        newStage.add(layer);

        const group = new Konva.Group({
            draggable: true,
        });
        layer.add(group);

        let konvaBackgroundImage: Konva.Image;
        const backgroundImage = new window.Image();
        backgroundImage.crossOrigin = 'anonymous'; // Allow CORS
        if (backgroundImageSrc) backgroundImage.src = backgroundImageSrc;

        konvaBackgroundImage = new Konva.Image({
            x: 0,
            y: 0,
            image: backgroundImage,
            width: newStage.width() / 1.5,
            height: newStage.height() / 1.5,
        });

        group.add(konvaBackgroundImage);
        layer.draw();

        const overlayImage = new window.Image();
        overlayImage.src = 'https://ik.imagekit.io/ryr9cg3ij/1080_1080.png';
        overlayImage.crossOrigin = 'anonymous'; // Allow CORS
        overlayImage.onload = () => {
            const konvaOverlayImage = new Konva.Image({
                x: padding,
                y: padding,
                image: overlayImage,
                width: newStage.width() - 2 * padding,
                height: newStage.height() - 2 * padding,
                draggable: false,
            });

            layer.add(konvaOverlayImage);
            konvaOverlayImage.listening(false);

            const newTransformer = new Konva.Transformer({
                nodes: [konvaBackgroundImage],
                keepRatio: true,
                anchorFill: 'blue',
                anchorStroke: 'blue',
                anchorSize: 10,
            });

            layer.add(newTransformer);
            setTransformer(newTransformer);
            layer.draw();
        };

        const handleResize = () => {
            const newWidth = konvaContainer.offsetWidth;
            const newHeight = konvaContainer.offsetHeight;
            newStage.width(newWidth);
            newStage.height(newHeight);

            if (konvaBackgroundImage) {
                konvaBackgroundImage.width(newWidth / 1.5);
                konvaBackgroundImage.height(newHeight / 1.5);
            }

            layer.getChildren().forEach((node) => {
                if (node instanceof Konva.Image && node !== konvaBackgroundImage) {
                    node.width(newWidth - 2 * padding);
                    node.height(newHeight - 2 * padding);
                    node.position({ x: padding, y: padding });
                }
            });

            layer.draw();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [backgroundImageSrc]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setBackgroundImageSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDownload = () => {
        if (!stage || !transformer) return;

        // Temporarily hide the transformer
        transformer.visible(false);
        stage.batchDraw(); 

        // Convert the Data URL to a Blob
        // Export the canvas as a Blob and create a downloadable link
        stage.toCanvas().toBlob((blob) => {
            if (blob) {
                // Create a download link
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'canvas-image.png';

                // Append link to the body, click it, and remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Revoke the object URL to free up memory
                URL.revokeObjectURL(link.href);

                // Restore the transformer visibility immediately
                transformer.visible(true);
                stage.batchDraw();
            } else {
                console.error('Failed to create a Blob from the canvas');
            }

            // Restore transformer visibility after download
            transformer.visible(true);
            stage.batchDraw();
        }, 'image/png'); // Specify the image format as PNG
    };

    const rating = 4.5; // Example rating value (change as needed)

    const renderStars = (rating: number) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} style={{ color: '#ffc107', marginRight: 2 }} />);
            } else if (i - rating < 1) {
                stars.push(<FaStarHalfAlt key={i} style={{ color: '#ffc107', marginRight: 2 }} />);
            } else {
                stars.push(<FaRegStar key={i} style={{ color: '#ffc107', marginRight: 2 }} />);
            }
        }
        return stars;
    };

    return (
        <Layout>
            <div className="container-fluid" style={{ backgroundColor: '#f4f9f7', paddingTop: '30px', paddingBottom: '30px' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-5 konva-container-wrapper" ref={konvaContainerRef}>
                        <div id="container"></div>
                    </div>
                    <div className="product-details col-md-7">
                        <input type="file" accept="image/*" onChange={handleImageChange} className='py-1' />
                        <h1 className='py-1'>Portrait ACRYLIC FRAME</h1>
                        {/* Star Rating */}
                        <div className="review-stars" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            {renderStars(rating)}
                            <span style={{ marginLeft: '8px', fontSize: '1rem', color: '#555' }}>
                                {rating.toFixed(1)} / 5
                            </span>
                        </div>

                        <h2>$93</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                        <div className="row" style={{ marginTop: '10px' }}>
                            {/* Size Selection */}
                            <div className="col-12 col-md-6 mb-3">
                                <label htmlFor="size" style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px' }}>Size:</label>
                                <select
                                    id="size"
                                    value={size}
                                    onChange={(e) => setSize(e.target.value)}
                                    style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                                >
                                    <option value="Small">Small</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Large">Large</option>
                                    <option value="Extra Large">Extra Large</option>
                                </select>
                            </div>

                            {/* Quantity Selection */}
                            <div className="col-12 col-md-6 mb-3">
                                <label htmlFor="quantity" style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px' }}>Quantity:</label>
                                <select
                                    id="quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                                    style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                                >
                                    {Array.from({ length: 100 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Category and Type Display */}
                            <div className="row" >
                                {/* Display Category */}
                                <div className="col-12 col-md-6">
                                    <span style={{ marginRight: '8px', fontSize: '1rem', color: '#88c0b1', fontWeight: 'bold' }}>
                                        Category:
                                    </span>
                                    PhotoFrame
                                </div>

                                {/* Type Selection */}
                                <div className="col-12 col-md-6">
                                    <span style={{ marginRight: '8px', fontSize: '1rem', color: '#88c0b1', fontWeight: 'bold' }}>
                                        Type:
                                    </span>
                                    Wall PhotoFrame
                                </div>
                            </div>

                            {/* Add to Cart and Buy Now Buttons */}
                            <div className="row" style={{ marginTop: '10px' }}>
                                <div className="col-6 mb-3">
                                    <button
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: 'none',
                                            backgroundColor: '#88c0b1',
                                            color: '#fff',
                                            fontSize: '1rem',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Add to Cart
                                    </button>
                                </div>
                                <div className="col-6 mb-3">
                                    <button
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: 'none',
                                            backgroundColor: '#88c0b1',
                                            color: '#fff',
                                            fontSize: '1rem',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleDownload}
                                    >
                                        Buy Now
                                    </button>
                                </div>
                            </div>

                            {/* Social Media Icons */}
                            <div className="social-media" style={{ marginTop: '5px', display: 'flex', justifyContent: 'flex-start' }}>
                                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                                    <FaFacebook size={24} style={{ color: '#4267B2' }} />
                                </a>
                                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                                    <FaInstagram size={24} style={{ color: '#C13584' }} />
                                </a>
                                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                                    <FaTwitter size={24} style={{ color: '#1DA1F2' }} />
                                </a>
                                <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                                    <FaYoutube size={24} style={{ color: '#FF0000' }} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                <div className="row" style={{ marginTop: '10px' }}>
                    {/* Size Selection */}
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="size" style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px', fontSize: '25px' }}>The Print Pyramid Promise:</label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>

                    {/* Quantity Selection */}
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="quantity" style={{ fontWeight: 'bold', display: 'block', marginBottom: '5px', fontSize: '25px' }}>Shipping:</label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div> 
                </div> 
            </div>
        </div>
        </Layout>
    );
};

export default ProductDetails;
