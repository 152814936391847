import React from 'react';
import { banner, banner2 } from '../../../assets';
import { ImageSlider, ListTile } from '../../../components';
import { ROUTE_PATHS } from '../../../routes';
const banner3 = 'https://placehold.co/600x350/png';
const banners = [banner3, banner3, banner3, banner3, banner3, banner3];

const DefaultCategoryContent: React.FC = () => {
    return (
        <div className='pt-4'>
            <img src={banner} alt="Banner" className="img-fluid" />

            {/* Pocket Friendly Designs */}
            <div className="d-flex flex-column justify-content-center align-items-center py-4 text-center">
                <span className="fs-3 fw-medium text-dark mb-2">
                    Pocket Friendly Designs from Top Brands
                </span>
                <span >
                    Vestibulum varius eleifend dapibus. Praesent malesuada elementum ipsum, id cursus turpis eleifend a.
                </span>
            </div>

            {/* Grid for banner images */}
            <div className="row">
                {banners.map((bannerImage: string | undefined, index: number) => (
                    <div className="col-12 col-md-4 mb-4" key={index}>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <img
                                src={bannerImage}
                                alt={`Banner ${index + 1}`}
                                className="img-fluid rounded"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Image fills the container
                            />
                        </div>
                    </div>
                ))}
            </div>

            <img src={banner2} alt="Banner" className="img-fluid py-4" />

            <ListTile
                title="DESK DECORATIVES"
                route={ROUTE_PATHS.DASHBOARD + '/item-one'} // Dynamic route for Item One
                isMoreButton={true} // Show right arrow icon
            />
            <ImageSlider images={[
                { url: "https://placehold.co/600x400/png", text: "Card 1 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 2 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" }
            ]} />

            <ListTile
                title="HOME DECORATIVES"
                route={ROUTE_PATHS.DASHBOARD + '/item-one'} // Dynamic route for Item One
                isMoreButton={true} // Show right arrow icon
            />
            <ImageSlider images={[
                { url: "https://placehold.co/600x400/png", text: "Card 1 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 2 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" }
            ]} />

            <ListTile
                title="OUR MORE CUSTOM COLLECTIONS"
                route={ROUTE_PATHS.DASHBOARD + '/item-one'} // Dynamic route for Item One
                isMoreButton={true} // Show right arrow icon
            />
            <ImageSlider images={[
                { url: "https://placehold.co/600x400/png", text: "Card 1 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 2 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" }
            ]} />

            <ListTile
                title="OFFICE STATIONERY"
                route={ROUTE_PATHS.DASHBOARD + '/item-one'} // Dynamic route for Item One
                isMoreButton={true} // Show right arrow icon
            />
            <ImageSlider images={[
                { url: "https://placehold.co/600x400/png", text: "Card 1 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 2 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" },
                { url: "https://placehold.co/600x400/png", text: "Card 3 description" }
            ]} /> 

            <br /><br />
        </div>
    );
};

export default DefaultCategoryContent;
