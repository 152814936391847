import React from 'react'; 
import { Layout } from '../../components';
 

const TermsAndConditions: React.FC = () => {
    return (
        <Layout><div className="container-fuild"  style={{ backgroundColor: '#f4f9f7'}}><div className="container py-4">
        <br/>
        <h1 className="text-center mb-4">Terms and Conditions</h1>
        <p className="text-muted text-center">Last updated: [Insert Date]</p>
        <br/>
        <div className="mb-4">
            <h3>Introduction</h3>
            <p>
                Welcome to [Your Company Name]! These terms and conditions outline the rules and regulations for the use of our website, located at [Your Website URL].
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Acceptance of Terms</h3>
            <p>
                By accessing this website, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, you must not use our website.
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Use of the Website</h3>
            <p>
                You may not use the website in any way that causes damage to the website or impairment of the availability or accessibility of the website. You may not use the website in any unlawful manner.
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Intellectual Property Rights</h3>
            <p>
                Other than the content you own, under these Terms, [Your Company Name] and/or its licensors own all the intellectual property rights and materials contained in this website.
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Limitation of Liability</h3>
            <p>
                In no event shall [Your Company Name], nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise.
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Modifications to Terms</h3>
            <p>
                We reserve the right to modify these terms at any time. By continuing to use the website after such modifications, you agree to be bound by the modified terms.
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Governing Law</h3>
            <p>
                These terms will be governed by and construed in accordance with the laws of [Your Jurisdiction], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your Jurisdiction] for the resolution of any disputes.
            </p>
        </div>
        <br/>
        <div className="mb-4">
            <h3>Contact Us</h3>
            <p>
                If you have any questions about these Terms, please contact us at [Your Contact Information].
            </p>
        </div>
    </div></div></Layout>
    );
};

export default TermsAndConditions;
