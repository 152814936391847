const ROUTE_PATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  DASHBOARD: '/dashboard',
  CATEGORY: '/dashboard/:category', // Add category route
  PRODUCT_DETAILS: '/product/:id',
  TERMSANDCONDITIONS: '/terms-and-conditions',
  PRIVACYPOLICY: '/privacy-policy',
  EditProfile: '/edit-profile',
};

export default ROUTE_PATHS;
