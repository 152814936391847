import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Footer, Header } from "../../components";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (e:any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (isEditing) {
      console.log("Form submitted:", formData);
    }
    setIsEditing(!isEditing);
  };

  return (
    <>
      <Header />
      <Container>
        <div className="mt-3 mb-5">
          <h1 className="text-center">Edit Profile</h1>
          <div className="mt-5">
            <h3>Your Personal Details</h3>
            <hr />
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={!isEditing} // Toggle between enabled/disabled
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <div className="d-flex justify-content-between align-items-center">
                <Button
                  variant="secondary"
                  className="px-4 py-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  variant={isEditing ? "success" : "primary"} // Change color based on mode
                  className="py-2 px-3"
                  type="submit"
                >
                  {isEditing ? "Submit" : "Edit"} {/* Toggle button text */}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default EditProfile;
