import React, { useEffect, useRef, useState } from "react";
import '../assets/css/Slider.css';

interface Image {
  url: string;
  text: string;
}

interface ImageSliderProps {
  images: Image[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const imageListRef = useRef<HTMLUListElement | null>(null);
  const scrollbarThumbRef = useRef<HTMLDivElement | null>(null);
  const [maxScrollLeft, setMaxScrollLeft] = useState<number>(0);
  

  // Handle resizing and initial setup
  useEffect(() => {
    const imageList = imageListRef.current;
    if (imageList) {
      setMaxScrollLeft(imageList.scrollWidth - imageList.clientWidth);
      updateScrollThumbPosition();
      
      const handleResize = () => {
        setMaxScrollLeft(imageList.scrollWidth - imageList.clientWidth);
        updateScrollThumbPosition();
      };
      
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    // eslint-disable-next-line
  }, []);

  // Update scrollbar thumb position
  const updateScrollThumbPosition = () => {
    const imageList = imageListRef.current;
    const scrollbarThumb = scrollbarThumbRef.current;
    if (imageList && scrollbarThumb) {
      const scrollPosition = imageList.scrollLeft;
      const thumbPosition =
        (scrollPosition / maxScrollLeft) *
        (imageList.clientWidth - scrollbarThumb.offsetWidth);
      scrollbarThumb.style.left = `${thumbPosition}px`;
    }
  };

  // Handle image scrolling
  const handleThumbDrag = (e: React.MouseEvent) => {
    const scrollbarThumb = scrollbarThumbRef.current;
    if (scrollbarThumb) {
      const startX = e.clientX;
      const thumbPosition = scrollbarThumb.offsetLeft;
      const maxThumbPosition =
        imageListRef.current!.getBoundingClientRect().width - scrollbarThumb.offsetWidth;

      const handleMouseMove = (e: MouseEvent) => {
        const deltaX = e.clientX - startX;
        const newThumbPosition = thumbPosition + deltaX;
        const boundedPosition = Math.max(
          0,
          Math.min(maxThumbPosition, newThumbPosition)
        );
        const scrollPosition =
          (boundedPosition / maxThumbPosition) * maxScrollLeft;

        scrollbarThumb.style.left = `${boundedPosition}px`;
        imageListRef.current!.scrollLeft = scrollPosition;
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  return (
    <div>
      <div className="slider-wrapper py-4">
        <ul className="image-list" ref={imageListRef} onScroll={updateScrollThumbPosition}>
          {images.map((image, index) => (
            <div className="card" style={{ width: "18rem" }} key={index}>
              <img className="card-img-top" src={image.url} alt={`Product ${index + 1}`} />
              <div className="card-body">
                <h5>{image.text}</h5>
                <h6 style={{ fontWeight: 'normal' }}>From ₹20/-</h6>
              </div>
            </div>
          ))}
        </ul>
      </div>
      <div className="slider-scrollbar">
        <div className="scrollbar-track">
          <div
            className="scrollbar-thumb"
            ref={scrollbarThumbRef}
            onMouseDown={handleThumbDrag}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
