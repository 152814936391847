import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../routes';
import '../../assets/css/Auth.css';

const Register: React.FC = () => { 
  return (
    <div>
      <div className="auth-wrapper">
      <div className="left-side">
        <div className="image-content"></div>
      </div>
      <div className="right-side">
        <form className="auth-form">
          <div className="logo">
            {/* Replace with your logo */}
            <img src="/path-to-your-logo.png" alt="Logo" />
          </div>
          <h2>Register</h2>
          <p>
            Create an account by filling in the details below.
          </p>
          <div className="input-container">
            <input type="text" id="username" name="username" placeholder="Username" required />
          </div>
          <div className="input-container">
            <input type="email" id="email" name="email" placeholder="Email" required />
          </div>
          <div className="input-container">
            <input type="password" id="password" name="password" placeholder="Password" required />
          </div>
          <button type="submit" className="auth-button">
            Register
          </button>
          <div className="links">
            <p>Already have an account?&nbsp;</p> 
            <Link to={ROUTE_PATHS.LOGIN}>Login</Link>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Register;
