import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../routes';
import '../../assets/css/Auth.css';

const Login: React.FC = () => {
   
  return (
    <div>
      <div className="auth-wrapper">
      <div className="left-side">
        <div className="image-content">
        </div>
      </div>
      <div className="right-side">
        <form className="auth-form">
          <div className="logo">
            {/* Replace with your logo */}
            <img src="/path-to-your-logo.png" alt="Logo" />
          </div>
          <h2>Login</h2>
          <p>Welcome back! Please enter your details to log in to your account.</p>
          <div className="input-container">
            <input type="email" id="email" name="email" placeholder="Email" required />
          </div>
          <div className="input-container">
            <input type="password" id="password" name="password" placeholder="Password" required />
          </div>
          <div className="links forgot-password">
            <Link to={ROUTE_PATHS.FORGOT_PASSWORD}>Forgot Password?</Link>
          </div>
          <button type="submit" className="auth-button">Login</button>
          <div className="links">
            <p>Don't have an account?&nbsp;</p>
            <Link to={ROUTE_PATHS.REGISTER}>Register</Link>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Login;
