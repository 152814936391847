import React from 'react';
import { Layout } from '../../components';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams, useNavigate } from 'react-router-dom';
import { DefaultCategoryContent, SelectedCategoryContent } from '.';

const categories = [
  { title: "AI Acrylics", slug: "ai-acrylics", id: 2 },
  { title: "AI T-Shirts", slug: "ai-t-shirts", id: 3 },
  { title: "Photo Rakhi", slug: "photo-rakhi", id: 4 },
  { title: "Name Pencils", slug: "name-pencils", id: 5 },
  { title: "Mobile Cases", slug: "mobile-cases", id: 6 },
  { title: "Couple Mobile Cases", slug: "couple-mobile-cases", id: 7 },
  { title: "Cuboid Chains", slug: "cuboid-chains", id: 8 },
  { title: "Sipper Bottles", slug: "sipper-bottles", id: 9 },
  { title: "Snapbooks", slug: "snapbooks", id: 10 },
  { title: "Wall Decoratives", slug: "wall-decoratives", id: 11 },
  { title: "Photo Stands", slug: "photo-stands", id: 12 },
  { title: "Mugs", slug: "mugs", id: 13 },
  { title: "T-Shirts", slug: "t-shirts", id: 14 },
  { title: "Pop Sockets", slug: "pop-sockets", id: 15 },
];

const Dashboard: React.FC = () => {
    const { category } = useParams<{ category?: string }>(); // Get category from URL
    const navigate = useNavigate(); // For programmatic navigation
    const [value, setValue] = React.useState<number | null>(null);

    // Set the initial tab based on the category, or null for no selection
    React.useEffect(() => {
        if (!category) {
            setValue(null); // No selection for the default route
        } else {
            const selectedCategoryIndex = categories.findIndex(cat => cat.slug === category);
            setValue(selectedCategoryIndex !== -1 ? selectedCategoryIndex : null);
        }
    }, [category]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        const selectedCategory = categories[newValue].slug;
        navigate(`/dashboard/${selectedCategory}`); // Update the URL with the selected category
    };

    return (
        <Layout>
            <div className='container pt-2'>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        }}
                    >
                        {categories.map((cat) => (
                            <Tab key={cat.id} label={cat.title} />
                        ))}
                    </Tabs>
                </Box>

                {/* Tab Content */}
                <div className="tab-content">
                    {value === null ? (
                        <DefaultCategoryContent />
                    ) : (
                        <SelectedCategoryContent category={categories[value].title} />
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
