import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../routes';

interface Product {
    id: number;
    title: string;
    subtitle: string;
    price: number;
    category: string;
    image: string;
}

interface SelectedCategoryContentProps {
    category: string;
}

const SelectedCategoryContent: React.FC<SelectedCategoryContentProps> = ({ category }) => {
    const navigate = useNavigate();

    const products: Product[] = [
        {
            "id": 1,
            "title": "Product 1",
            "subtitle": "This is the subtitle for product 1",
            "price": 29.99,
            "category": "Electronics",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 2,
            "title": "Product 2",
            "subtitle": "This is the subtitle for product 2",
            "price": 49.99,
            "category": "Fashion",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 3,
            "title": "Product 3",
            "subtitle": "This is the subtitle for product 3",
            "price": 19.99,
            "category": "Home & Kitchen",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 4,
            "title": "Product 4",
            "subtitle": "This is the subtitle for product 4",
            "price": 89.99,
            "category": "Sports",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 5,
            "title": "Product 5",
            "subtitle": "This is the subtitle for product 5",
            "price": 39.99,
            "category": "Books",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 6,
            "title": "Product 6",
            "subtitle": "This is the subtitle for product 6",
            "price": 59.99,
            "category": "Toys",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 7,
            "title": "Product 7",
            "subtitle": "This is the subtitle for product 7",
            "price": 15.99,
            "category": "Beauty",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 8,
            "title": "Product 8",
            "subtitle": "This is the subtitle for product 8",
            "price": 24.99,
            "category": "Health",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 9,
            "title": "Product 9",
            "subtitle": "This is the subtitle for product 9",
            "price": 99.99,
            "category": "Automotive",
            "image": "https://placehold.co/600x350/png"
        },
        {
            "id": 10,
            "title": "Product 10",
            "subtitle": "This is the subtitle for product 10",
            "price": 39.99,
            "category": "Office Supplies",
            "image": "https://placehold.co/600x350/png"
        }
    ];

    // Filter products based on the selected category


    return (
        <div className="container mt-4">
            <h2 className="mb-4">{category} Products</h2>
            <div className="row">
                {products.length > 0 ? (
                    products.map(product => (
                        <div className="col-md-4 mb-4" key={product.id}>
                            <div className="card">
                                <img src={product.image} className="card-img-top" alt={product.title} />
                                <div className="card-body">
                                    <h5 className="card-title">{product.title}</h5>
                                    <p className="card-text">{product.subtitle}</p>
                                    <p className="card-text"><strong>Price: ${product.price.toFixed(2)}</strong></p>
                                    <button className="btn btn-primary" onClick={() => {
                                        navigate(ROUTE_PATHS.PRODUCT_DETAILS.replace(':id', product.id.toString()));
                                    }}>View Details</button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12">
                        <p>No products available in this category.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectedCategoryContent;