import React from 'react';

const Footer: React.FC = () => {
    return (
      <footer className="py-4 text-white" style={{ backgroundColor: 'var(--primary-color)' }}>
        <div className="container py-4">
          <div className="row align-items-start">
            {/* Left Side - About Section */}
            <div className="col-md-6 footer-left">
              <h2>The Print Pyramid</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />Nullam in ante vitae libero<br />consequat blandit.</p>
            </div>
  
            {/* Right Side - Additional Sections */}
            <div className="col-md-6 footer-right">
              <div className="row d-flex justify-content-between">
                <div className="col-md-4 mb-4 mb-md-0">
                  <h5>Section 1</h5>
                  <ul className="list-unstyled">
                    <li>Link 1</li>
                    <li>Link 2</li>
                    <li>Link 3</li>
                  </ul>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <h5>Section 2</h5>
                  <ul className="list-unstyled">
                    <li>Link 1</li>
                    <li>Link 2</li>
                    <li>Link 3</li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h5>Support</h5>
                  <ul className="list-unstyled">
                    <li>Terms & Condition</li>
                    <li>PrivacyPolicy</li>
                    <li>Link 3</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
          {/* Divider Section */}
          <hr className="my-4 border-light" /> {/* Bootstrap horizontal rule with light border */}
  
          {/* Copyright Section */}
          <div className="text-center mt-4">
            <small>&copy; 2024 Your Company. All rights reserved.</small>
          </div>
        </div>
      </footer>
    );
  };
  
export default Footer;
